import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 512 512"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g fill={fill}>
	<g>
		<path fill={fill} d="M258.77,336.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07
			c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93s2.93-4.44,2.93-7.07S260.64,338.79,258.77,336.93z"/>
	</g>
</g>
<g>
	<g>
		<path fill={fill} d="M410.224,375.862l-0.148-0.447c-1.736-5.243-7.394-8.084-12.637-6.349c-5.243,1.736-8.085,7.394-6.35,12.637l0.148,0.447
			c1.391,4.2,5.297,6.859,9.492,6.859c1.042,0,2.102-0.165,3.145-0.51C409.117,386.763,411.959,381.105,410.224,375.862z"/>
	</g>
</g>
<g>
	<g>
		<path fill={fill} d="M470.123,179.5h-16c-8.806,0-16.702,3.951-22.025,10.167h-18.975V186c0-5.523-4.477-10-10-10h-45.411
			c-4.459,0-8.473-2.476-10.475-6.461l-22.094-43.997C315.993,107.32,297.642,96,277.251,96h-8.835
			c7.551-9.29,12.087-21.123,12.087-34v-8c0-29.776-24.224-54-54-54s-54,24.224-54,54v8c0,12.877,4.537,24.71,12.088,34h-20.646
			c-23.759,0-44.938,15.948-51.504,38.781L89.346,215.1c-3.494,12.149-1.136,24.91,6.468,35.008
			c7.605,10.1,19.217,15.892,31.858,15.892h27.801v13.243L104.756,308.7c-25.631,14.887-44.892,38.578-54.232,66.708l-27.708,83.441
			c-1.013,3.049-0.499,6.398,1.381,9.003c0.325,0.451,0.695,0.857,1.083,1.241c-7.712,8.801-12.403,20.313-12.403,32.907
			c0,5.523,4.477,10,10,10h77.987c13.785,0,25-11.215,25-25v-15.041c3.713-0.209,7.029-2.444,8.564-5.865L166.779,394h127.038
			l32.35,72.094c1.535,3.421,4.851,5.656,8.564,5.865V487c0,1.712,0.174,3.384,0.503,5h-83.533c-5.523,0-10,4.477-10,10
			s4.477,10,10,10h186.018c5.523,0,10-4.477,10-10c0-12.594-4.69-24.107-12.403-32.907c0.388-0.385,0.758-0.791,1.083-1.241
			c1.88-2.605,2.394-5.954,1.381-9.003l-16.805-50.606c-1.74-5.241-7.397-8.08-12.642-6.339c-5.242,1.74-8.08,7.4-6.339,12.642
			L414.433,452h-16.714h-52.988h-2.967l-26.026-58h10.385c3.182,0,6.174-1.515,8.059-4.079c1.885-2.564,2.436-5.873,1.484-8.909
			l-23.309-74.438l33.439,19.421c12.741,7.4,23.919,17.706,32.328,29.802c1.943,2.795,5.056,4.293,8.22,4.293
			c1.969,0,3.959-0.581,5.699-1.79c4.535-3.152,5.656-9.384,2.504-13.919c-10.067-14.483-23.452-26.822-38.706-35.682
			l-50.718-29.456v-13.116h98c5.523,0,10-4.477,10-10v-4.46h18.974c5.323,6.216,13.219,10.167,22.026,10.167h16
			c15.991,0,29-13.009,29-29V208.5C499.123,192.509,486.113,179.5,470.123,179.5z M354.732,472h42.987
			c13.038,0,24.159,8.359,28.287,20h-66.274c-2.757,0-5-2.243-5-5V472z M192.504,54c0-18.748,15.252-34,34-34
			c18.748,0,34,15.252,34,34v8c0,18.748-15.252,34-34,34c-18.748,0-34-15.252-34-34V54z M261.123,266.127h16
			c1.067,0,2.12-0.062,3.157-0.175c0.594,0.11,1.205,0.175,1.831,0.175h3.012V275h0H175.474v-9h34.649c5.523,0,10-4.477,10-10v-0.04
			h18.975C244.421,262.176,252.317,266.127,261.123,266.127z M252.123,237.127v-24.333c0-4.962,4.038-9,9-9h16c4.962,0,9,4.038,9,9
			v24.333c0,4.962-4.038,9-9,9h-16C256.161,246.127,252.123,242.089,252.123,237.127z M232.123,235.96h-12v-22h12V235.96z
			 M245.409,112.577l-19.8,37.928L198.32,108.04c8.21,5.045,17.862,7.96,28.185,7.96C233.153,116,239.522,114.785,245.409,112.577z
			 M127.672,246c-6.301,0-12.089-2.887-15.88-7.921s-4.966-11.395-3.225-17.451l23.096-80.32
			c4.115-14.312,17.39-24.308,32.283-24.308h15.716l50.097,77.96h-9.846c-0.942-4.545-4.967-7.96-9.791-7.96h-52
			c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10h42v39.96V246H127.672z M105.865,487c0,2.757-2.243,5-5,5H34.591
			c4.127-11.641,15.249-20,28.287-20h42.987V487z M118.832,452h-2.967H62.877H46.163l23.341-70.29
			c7.802-23.495,23.889-43.282,45.297-55.716l33.439-19.421l-23.309,74.438c-0.951,3.037-0.4,6.345,1.484,8.909
			c1.884,2.564,4.876,4.079,8.059,4.079h10.384L118.832,452z M312.513,374H148.084l6.263-20h58.1c5.523,0,10-4.477,10-10
			s-4.477-10-10-10h-51.838l12.212-39h114.954L312.513,374z M393.123,199.667v42v4.46h0h-88.437c0.928-2.835,1.437-5.859,1.437-9
			v-24.333c0-15.991-13.009-29-29-29h-16c-4.414,0-8.598,0.995-12.346,2.767l-10.72-16.683L266.183,116h11.069
			c12.781,0,24.283,7.095,30.019,18.517l22.094,43.997C334.78,189.3,345.643,196,357.712,196h35.411V199.667z M425.123,231.667h-12
			v-22h12V231.667z M479.123,232.833c0,4.962-4.038,9-9,9h-16c-4.962,0-9-4.038-9-9V208.5c0-4.962,4.038-9,9-9h16
			c4.962,0,9,4.038,9,9V232.833z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
    </svg>
)

export default SVG;