import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 103 103"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
	<g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
	<g id="Artboard" transform="translate(-2399.000000, -197.000000)" fill={fill} fillRule="nonzero">
		<g id="snowboard" transform="translate(2399.000000, 197.000000)">
			<path d="M48.2,0 C43.2372,0 39.2,4.2372 39.2,9.2 C39.2,14.1628 43.237,18.4 48.2,18.4 C53.1628,18.4 57.2,14.1628 57.2,9.2 C57.2,4.2372 53.1628,0 48.2,0 Z" id="Path"></path>
			<path d="M86.5402,51.6998 L80.6002,39.6998 C80,38.5598 79.0402,37.6 77.9002,37.0598 L53.72,24.94 C53,24.6398 51.7402,24.4598 50.66,24.4598 C50.36,24.4598 50.36,24.4598 20,30.5198 C16.4644,31.227 14.625,34.8194 15.32,37.6 C15.9892,41.0212 19.5114,43.002 22.4,42.2798 L42.0798,38.32 L42.38,38.26 L44.7198,49.84 L25.76,54.5802 C22.968,55.3034 20.7964,57.9984 21.26,61.2402 C22.7068,66.0456 22.2194,64.4266 27.4998,80.3202 C28.5196,83.3802 31.9398,85.1202 35.12,84.1002 C38.6722,82.6774 39.7932,79.3384 38.9,76.48 L34.9398,64.6602 C35.5522,64.506 44.8524,62.166 44.24,62.3202 C44.18,63.4002 44.3598,64.5402 44.7198,65.6202 C44.9022,66.1772 50.7582,84.057 51.4394,86.1364 C52.6886,89.9486 56.7846,91.251 59.6212,89.879 C62.7764,88.3538 63.7494,85.1992 62.8998,82.48 L58.5198,69.46 L62.06,67.66 C64.4602,66.46 65.78,63.7598 65.2998,61.1198 L61.5798,42.2798 C62.1884,42.5882 71.3086,47.2082 70.7,46.9 L75.86,57.1 C77.3,59.9798 81.02,61.18 83.9002,59.8 C83.9002,59.8 83.9602,59.8 83.9602,59.74 C86.894,58.2092 88.0204,54.66 86.5402,51.6998 Z" id="Path"></path>
			<path d="M67.8534,70.6774 C67.236,71.3066 66.5948,71.9184 65.8396,72.403 L68.5876,80.568 C70.4656,86.568 67.8376,92.568 62.239,95.278 C56.0416,98.2704 48.086,95.17 45.739,88.0036 L44.1746,83.234 C42.3582,87.3392 38.1124,90.3942 33.2556,90.3942 C28.0348,90.3942 23.4352,87.107 21.8064,82.2146 C21.5024,81.3012 21.2546,80.5574 20.9876,79.7554 L17.6198,96.594 L77.7004,101.6606 L83.6232,72.0506 L67.8534,70.6774 Z" id="Path"></path>
			<path d="M16.5636,66.4014 L16.442,66.3912 C8.1656,65.7496 0.7322,71.661 0.0526,79.8962 C-0.5538,87.1484 4.3044,94.0884 11.647,95.8678 L17.1672,68.2646 C16.9014,67.456 16.7226,66.9042 16.5636,66.4014 Z" id="Path"></path>
			<path d="M89.6184,72.6666 L83.7188,102.1624 C85.5476,102.3034 86.1856,102.4 87.2146,102.4 C94.946,102.4 101.7056,96.359 102.3472,88.6452 C102.9826,81.0504 97.6384,73.9722 89.6184,72.6666 Z" id="Path"></path>
		</g>
	</g>
	</g>
    </svg>
)

export default SVG;