import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 103 63"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
	<g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
	<g id="Artboard" transform="translate(-2399.000000, -77.000000)" fill={fill} fillRule="nonzero">
		<g id="racing-car" transform="translate(2399.000000, 77.000000)">
			<path d="M10.9510348,56.6758743 L7.30068971,56.6758743 L7.30068971,60.3262191 C7.30068971,61.3337486 8.11794274,62.1510017 9.12625222,62.1510017 L23.7276318,62.1510017 C24.7359411,62.1510017 25.5531941,61.3337486 25.5531941,60.3262191 L25.5531941,54.8503117 C25.5531941,54.5337043 25.4697532,54.2225554 25.3106696,53.948838 C24.9870436,53.3787883 24.382682,53.0263092 23.7276318,53.0247494 L14.6021594,53.0247494 C14.6021594,55.0413682 12.9676533,56.6758743 10.9510348,56.6758743 Z" id="Path"></path>
			<polygon id="Path" points="14.6021594 49.3744046 18.2525044 49.3744046 18.2525044 43.9078551 14.6021594 42.4932588"></polygon>
			<path d="M23.7276318,0.0935785919 L9.12625222,0.0935785919 C8.11794274,0.0935785919 7.30068971,0.910831627 7.30068971,1.91836113 L7.30068971,5.56948596 L10.9510348,5.56948596 C12.9676533,5.56948596 14.6021594,7.20321206 14.6021594,9.21983081 L23.7276318,9.21983081 C24.7359411,9.21983081 25.5531941,8.40257778 25.5531941,7.3942683 L25.5531941,1.91836113 C25.5531941,0.910831627 24.7359411,0.0935785919 23.7276318,0.0935785919 Z" id="Path"></path>
			<polygon id="Path" points="14.6021594 19.7513215 18.2525044 18.336725 18.2525044 12.8701757 14.6021594 12.8701757"></polygon>
			<path d="M71.1836751,1.91836113 L85.7858347,1.91836113 C86.7941439,1.91836113 87.6106172,2.73561417 87.6106172,3.74392345 L87.6106172,9.21983081 C87.6106172,10.2273603 86.7941439,11.0446134 85.7858347,11.0446134 L71.1836751,11.0446134 C70.1761458,11.0446134 69.3588927,10.2273603 69.3588927,9.21983081 L69.3588927,3.74392345 C69.3588927,2.73561417 70.1761458,1.91836113 71.1836751,1.91836113 L71.1836751,1.91836113 Z" id="Path"></path>
			<path d="M98.5624317,7.3942683 C97.5541223,7.3942683 96.7368692,8.21152134 96.7368692,9.21983081 L96.7368692,24.8817678 L100.387214,25.4510375 L100.387214,9.21983081 C100.387214,8.21152134 99.570741,7.3942683 98.5624317,7.3942683 Z" id="Path"></path>
			<polygon id="Path" points="80.3099273 22.3153749 80.3099273 14.6949582 76.6595824 14.6949582 76.6595824 21.7445455"></polygon>
			<path d="M21.9028493,12.8701757 L21.9028493,16.9221287 L37.0649205,11.0446134 L27.765548,11.0446134 C26.743202,12.2034281 25.2732381,12.8686161 23.7276318,12.8701757 L21.9028493,12.8701757 Z" id="Path"></path>
			<path d="M0,53.0247494 L10.9510348,53.0247494 L10.9510348,9.21983081 L0,9.21983081 L0,53.0247494 Z M3.65034485,14.6949582 C3.65034485,13.6874287 4.46759789,12.8701757 5.47590737,12.8701757 C6.48343667,12.8701757 7.30068971,13.6874287 7.30068971,14.6949582 L7.30068971,16.5205205 C7.30068971,17.52883 6.48343667,18.346083 5.47590737,18.346083 C4.46759789,18.346083 3.65034485,17.52883 3.65034485,16.5205205 L3.65034485,14.6949582 Z M3.65034485,23.8212104 C3.65034485,22.8136809 4.46759789,21.9964279 5.47590737,21.9964279 C6.48343667,21.9964279 7.30068971,22.8136809 7.30068971,23.8212104 L7.30068971,32.9474624 C7.30068971,33.9557719 6.48343667,34.773025 5.47590737,34.773025 C4.46759789,34.773025 3.65034485,33.9557719 3.65034485,32.9474624 L3.65034485,23.8212104 Z" id="Shape"></path>
			<polygon id="Path" points="76.6595824 40.5000348 76.6595824 47.549622 80.3099273 47.549622 80.3099273 39.9292054"></polygon>
			<path d="M45.6304809,27.4715553 L38.3297912,27.4715553 L38.3297912,34.773025 L45.6304809,34.773025 C47.6470997,34.773025 49.280826,33.1385189 49.280826,31.1226801 C49.280826,29.1060614 47.6470997,27.4715553 45.6304809,27.4715553 Z" id="Path"></path>
			<polygon id="Path" points="34.6794464 34.1647641 34.6794464 28.0798161 24.251672 31.1226801"></polygon>
			<path d="M100.387214,53.0247494 L100.387214,36.7935428 L96.7368692,37.3628124 L96.7368692,53.0247494 C96.7368692,54.0330587 97.5541223,54.8503117 98.5624317,54.8503117 C99.570741,54.8503117 100.387214,54.0330587 100.387214,53.0247494 Z" id="Path"></path>
			<path d="M53.1230069,20.9865589 C52.1513492,18.9020958 51.5937767,16.6491912 51.4807027,14.3518367 C51.2873069,12.4826044 49.7183059,11.0578703 47.8389357,11.0445943 C47.3609051,11.0430536 46.8875535,11.137412 46.4469542,11.3222298 L14.6021594,23.6683654 L14.6021594,38.5762148 L46.3993851,50.9020749 C46.8540212,51.0978102 47.3437491,51.1991871 47.8389357,51.1999669 C49.7159663,51.1820309 51.2795088,49.757297 51.4713448,47.890404 C51.5844189,45.5946092 52.1427712,43.3417047 53.114429,41.2580214 C53.3787883,40.7293022 53.8825532,40.3612264 54.4666395,40.2699874 L101.429836,32.932646 C101.84938,32.8687005 102.200299,32.0405301 102.200299,31.1226801 C102.200299,30.2040502 101.84938,29.3758797 101.433735,29.3119343 L54.4666395,21.9745929 C53.8856725,21.8810143 53.3858069,21.5129385 53.1230069,20.9865589 Z M45.6304809,38.4233698 L36.5042289,38.4233698 C36.3318884,38.4233698 36.1595477,38.3984155 35.9934457,38.3500665 L20.2160951,33.7467797 C19.0549408,33.4036582 18.2571833,32.3392018 18.2540548,31.1281387 C18.2509447,29.9170758 19.0440232,28.8479404 20.203618,28.49936 L35.9934457,23.8945136 C36.079226,23.8804769 36.1657862,23.8734585 36.2523465,23.8726786 C36.3350075,23.849284 36.4192284,23.8321278 36.5042289,23.8212104 L45.6304809,23.8212104 C49.6629385,23.8212104 52.9319506,27.0902226 52.9319506,31.1226801 C52.9319506,35.1543577 49.6629385,38.4233698 45.6304809,38.4233698 L45.6304809,38.4233698 Z" id="Shape"></path>
			<path d="M21.9028493,49.3744046 L23.7276318,49.3744046 C25.2732381,49.3767441 26.743202,50.0411519 27.765548,51.1999669 L37.0742785,51.1999669 L21.9028493,45.3224516 L21.9028493,49.3744046 Z" id="Path"></path>
			<path d="M71.1836751,51.1999669 L85.7858347,51.1999669 C86.7941439,51.1999669 87.6106172,52.0172199 87.6106172,53.0247494 L87.6106172,58.5006566 C87.6106172,59.5089661 86.7941439,60.3262191 85.7858347,60.3262191 L71.1836751,60.3262191 C70.1761458,60.3262191 69.3588927,59.5089661 69.3588927,58.5006566 L69.3588927,53.0247494 C69.3588927,52.0172199 70.1761458,51.1999669 71.1836751,51.1999669 L71.1836751,51.1999669 Z" id="Path"></path>
		</g>
	</g>
</g>
    </svg>
)

export default SVG;