import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 93 81"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px" 
    y="0px"
    >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-1993.000000, -79.000000)">
            <g id="Group-2" transform="translate(1993.000000, 79.000000)">
                <g id="man-cycling" transform="translate(0.000000, 41.459040)" fill={fill} fillRule="nonzero">
                    <path d="M73.2564,0.18144 C62.46072,0.18144 53.9784,8.66376 53.9784,19.45944 C53.9784,30.25512 62.46072,38.73744 73.2564,38.73744 C84.05208,38.73744 92.5344,30.2552712 92.5344,19.45944 C92.5344,8.6636088 84.05208,0.18144 73.2564,0.18144 Z M73.2564,32.95404 C65.93076,32.95404 59.7618,26.7849288 59.7618,19.45944 C59.7618,12.1336488 65.93076,5.96484 73.2564,5.96484 C80.58204,5.96484 86.751,12.1336488 86.751,19.45944 C86.751,26.7849288 80.58204,32.95404 73.2564,32.95404 Z M19.278,0.18144 C8.48232,0.18144 0,8.66376 0,19.45944 C0,30.25512 8.48232,38.73744 19.278,38.73744 C30.07368,38.73744 38.556,30.2552712 38.556,19.45944 C38.556,8.6636088 30.07368,0.18144 19.278,0.18144 Z M19.278,32.95404 C11.95236,32.95404 5.7834,26.7849288 5.7834,19.45944 C5.7834,12.1336488 11.95236,5.96484 19.278,5.96484 C26.60364,5.96484 32.7726,12.1336488 32.7726,19.45944 C32.7726,26.7849288 26.60364,32.95404 19.278,32.95404 Z" id="Shape"></path>
                </g>
                <path d="M61.6896,13.88016 C65.5452,13.88016 68.62968,10.79568 68.62968,6.94008 C68.62968,3.08448 65.5452,4.83453277e-14 61.6896,4.83453277e-14 C57.834,4.83453277e-14 54.74952,3.08448 54.74952,6.94008 C54.74952,10.79568 57.834,13.88016 61.6896,13.88016 Z" id="Path" fill={fill}></path>
                <path d="M57.06288,33.92928 L73.2564,33.92928 L73.2564,26.9892 L60.91848,26.9892 L53.59284,14.26572 C52.43616,12.33792 50.50836,11.18124 47.80944,11.18124 C45.88164,11.18124 44.3394,11.95236 43.18272,13.10904 L28.917,27.37476 C27.76032,28.53144 26.9892,30.07368 26.9892,32.00148 C26.9892,34.31484 28.14588,36.6282 30.07368,37.78488 L43.18272,45.49608 L43.18272,64.77408 L50.1228,64.77408 L50.1228,39.71268 L41.64048,33.15816 L50.50836,24.29028 L57.06288,33.92928 Z" id="Path" fill={fill}></path>
            </g>
        </g>
    </g>
    </svg>
)

export default SVG;