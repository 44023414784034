import React from 'react'

const SVG = ({
    style = {},
    fill = "#00ABAC",
    stroke = "none",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 50 50"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g id="Desktop" stroke={stroke} strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-79.000000, -1422.000000)" fill={fill} fillRule="nonzero">
            <g id="youtube" transform="translate(79.000000, 1422.000000)">
                <polygon id="Path" points="21.8860626 29.6836854 30.0182343 25 21.8860626 20.3163146"></polygon>
                <path d="M25,0 C11.1949921,0 0,11.1949921 0,25 C0,38.8050079 11.1949921,50 25,50 C38.8050079,50 50,38.8050079 50,25 C50,11.1949921 38.8050079,0 25,0 Z M40.6213197,25.0255585 C40.6213197,25.0255585 40.6213197,30.0956727 39.9780273,32.5405121 C39.6175385,33.8787079 38.5623932,34.9338531 37.2241974,35.2939605 C34.7793579,35.9375 25,35.9375 25,35.9375 C25,35.9375 15.2462006,35.9375 12.7758026,35.2684021 C11.4376068,34.9082947 10.3824615,33.852768 10.0219727,32.5145722 C9.3784332,30.0956727 9.3784332,25 9.3784332,25 C9.3784332,25 9.3784332,19.9302674 10.0219727,17.4854278 C10.3820801,16.147232 11.4631652,15.0661469 12.7758026,14.7060395 C15.2206421,14.0625 25,14.0625 25,14.0625 C25,14.0625 34.7793579,14.0625 37.2241974,14.7315979 C38.5623932,15.0917053 39.6175385,16.147232 39.9780273,17.4854278 C40.6471252,19.9302674 40.6213197,25.0255585 40.6213197,25.0255585 L40.6213197,25.0255585 Z" id="Shape"></path>
            </g>
        </g>
    </g>
    </svg>
)

export default SVG;