import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 82 103"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Artboard" transform="translate(-2057.000000, -197.000000)" fill={fill} fill-rule="nonzero">
        <g id="reading" transform="translate(2057.000000, 197.000000)">
            <path d="M55.488,38.8126 L56.7266,39.9464 L61.1024,32.1842 C62.0668,30.4734 63.7124,29.218 65.6172,28.7398 L73.6254,26.7298 L66.5906,20.2898 C65.986,19.7362 65.0236,19.8898 64.6212,20.6038 L55.2386,37.2478 C54.9476,37.764 55.051,38.4126 55.488,38.8126 L55.488,38.8126 Z" id="Path"></path>
            <path d="M63.5788,33.5804 L57.4598,44.4348 L58.7378,44.4172 C61.467,44.3796 63.8672,45.8368 65.1634,48.0294 L65.4716,47.952 L76.1384,29.0302 L66.3094,31.4974 C65.152,31.7878 64.1648,32.5408 63.5788,33.5804 L63.5788,33.5804 Z" id="Path"></path>
            <path d="M34.3606,24.9946 L22.085,26.5328 C20.424,26.7408 18.8982,27.515 17.7518,28.706 C18.9118,30.0384 19.7568,31.6712 20.1512,33.5052 L25.6308,58.9828 L36.025,58.9828 C34.4394,58.3362 33.2184,56.9724 32.7774,55.2878 L29.688,43.4888 L34.9888,54.1928 C35.8122,55.8556 37.5066,56.905 39.3582,56.905 C39.3804,56.905 39.403,56.9048 39.4256,56.9046 L58.9058,56.6364 C61.5986,56.5994 63.7516,54.3864 63.7146,51.6936 C63.6776,49.0006 61.461,46.8482 58.7716,46.885 L42.3644,47.111 L38.4274,39.1608 L42.7096,44.6382 L44.147,44.6184 L43.4834,39.3228 L44.9558,39.6846 C45.9846,39.9374 47.1102,39.8566 48.1426,39.3858 L52.2436,37.5154 C52.2944,36.9458 52.4652,36.379 52.7622,35.852 L59.8134,23.3438 L45.6372,29.8092 L41.7112,28.8446 C40.346,26.2294 37.4594,24.6064 34.3606,24.9946 L34.3606,24.9946 Z" id="Path"></path>
            <path d="M81.273,94.415 L65.1348,60.7866 C64.5708,59.6112 63.6428,58.6852 62.5236,58.1122 C61.4686,58.7256 60.2468,59.0856 58.9398,59.1036 L54.9638,59.1584 C61.3816,60.197 66.2986,65.7746 66.2986,72.481 L66.2986,90.261 L70.722,99.4786 C72.1132,102.3778 75.602,103.627 78.529,102.2224 C81.4428,100.824 82.6712,97.3286 81.273,94.415 Z" id="Path"></path>
            <circle id="Oval" transform="translate(26.341458, 11.173995) rotate(-87.764363) translate(-26.341458, -11.173995) " cx="26.3414584" cy="11.1739948" r="10.7602274"></circle>
            <path d="M52.8006,62.2994 C48.5576,62.2994 25.5384,62.2994 22.4952,62.2994 L16.7048,35.0228 C15.8842,31.1574 12.4714,28.3932 8.52,28.3932 C7.8846,28.3932 7.2692,28.465 6.6786,28.597 C2.3246,29.57 -0.6376,33.9152 0.3352,38.498 C0.7398,40.4036 12.3514,95.103 12.4882,95.7468 C13.031,98.3036 15.2884,100.1322 17.9022,100.1322 L18.1338,100.1322 L18.134,100.1322 L19.3628,100.1322 L19.3628,101.3848 C19.3628,102.1196 19.9586,102.7156 20.6936,102.7156 L24.698,102.7156 C25.433,102.7156 26.0288,102.1198 26.0288,101.3848 L26.0288,100.1322 L48.288,100.1322 L48.288,101.3848 C48.288,102.1196 48.8838,102.7156 49.6188,102.7156 L53.6232,102.7156 C54.3582,102.7156 54.954,102.1198 54.954,101.3848 L54.954,100.1322 L58.4354,100.1322 C60.9468,100.1322 62.9828,98.0962 62.9828,95.5848 L62.9828,72.481 C62.982,66.8578 58.4236,62.2994 52.8006,62.2994 Z" id="Path"></path>
        </g>
        </g>
    </g>
    </svg>
)

export default SVG;