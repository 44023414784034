import React from 'react'

const SVG = ({
    style = {},
    fill = "#00AB69",
    textFill = "",
    stroke = "#00AB69",
    width = "91px",
    height = "91px",
    className = "",
    viewBox = "0 0 91 91"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <title>A2BB6D09-168E-4887-B27D-1C7E8CBC93BB</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-327.000000, -1884.000000)" fill={fill} fillRule="nonzero" stroke={stroke} strokeWidth="3">
            <g id="tick-2" transform="translate(329.000000, 1886.000000)">
                <path d="M86.0249702,12.959087 C84.7216754,11.6823096 82.6070225,11.6801097 81.3037277,12.9536719 L41.2474847,52.0239064 L26.7953089,36.6733023 C25.5465199,35.3476199 23.4351547,35.2616552 22.0773541,36.4827587 C20.7205917,37.7040314 20.6337284,39.7698894 21.8825174,41.0967564 L38.6886526,58.946426 C39.3041358,59.6006369 40.1655009,59.9805395 41.0737582,59.9989847 C41.0981561,60 41.1216887,60 41.1450484,60 C42.0276966,60 42.8767762,59.6571569 43.5022954,59.0476205 L86.0183949,17.5773153 C87.3249774,16.3039223 87.3272268,14.2358644 86.0249702,12.959087 Z" id="Path"></path>
                <path d="M83.7210176,40.2210176 C81.9099902,40.2210176 80.4422051,41.6888027 80.4422051,43.5 C80.4422051,63.8707441 63.8707441,80.4422051 43.5,80.4422051 C23.1304453,80.4422051 6.55779492,63.8707441 6.55779492,43.5 C6.55779492,23.1304453 23.1304453,6.55779492 43.5,6.55779492 C45.3110273,6.55779492 46.7789824,5.09000977 46.7789824,3.27898242 C46.7789824,1.46778516 45.3110273,0 43.5,0 C19.5138281,0 0,19.5138281 0,43.5 C0,67.4851523 19.5138281,87 43.5,87 C67.4851523,87 87,67.4851523 87,43.5 C87,41.6889727 85.5322148,40.2210176 83.7210176,40.2210176 Z" id="Path"></path>
            </g>
        </g>
    </g>
    </svg>
)

export default SVG;