import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 63 86"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Artboard" transform="translate(-1704.000000, -356.000000)" fill={fill} fillRule="nonzero">
        <g id="exercise-with-dumbbell" transform="translate(1704.000000, 356.000000)">
            <path d="M61.7607,48.5838 L49.0059,23.8788 C48.4011,22.7088 46.8963,21.7926 45.5796,21.7926 L45.4689,21.7926 L42.7662,21.7926 L30.3228,21.7926 C30.2877,21.7926 30.2544,21.8016 30.2193,21.8034 C30.1842,21.8016 30.1518,21.7926 30.1158,21.7926 L17.964,21.7926 C17.4681,21.7926 17.064,21.3885 17.064,20.8926 L17.064,11.9259 L18.7326,11.9259 C19.2978,13.77 20.997,15.1245 23.0238,15.1245 C25.5096,15.1245 27.5319,13.1022 27.5319,10.6164 L27.5319,7.8354 C27.5319,5.3496 25.5096,3.3273 23.0238,3.3273 C20.997,3.3273 19.2978,4.6809 18.7326,6.5259 L8.8641,6.5259 C8.2989,4.6818 6.5997,3.3273 4.5729,3.3273 C2.0871,3.3273 0.0648,5.3496 0.0648,7.8354 L0.0648,10.6164 C0.0648,13.1022 2.0871,15.1245 4.5729,15.1245 C6.5997,15.1245 8.2989,13.7709 8.8641,11.9259 L10.6443,11.9259 L10.6443,26.5176 C10.6443,28.0062 11.8557,29.2176 13.3443,29.2176 L27.6228,29.2176 L27.6228,51.9804 L22.7187,63.1647 C22.1499,64.4625 21.7035,66.591 21.7035,68.0085 L21.7035,82.0809 C21.7035,83.8674 23.157,85.3209 24.9435,85.3209 L27.7803,85.3209 C29.5668,85.3209 31.0203,83.8674 31.0203,82.0809 L31.0203,68.0076 C31.0203,66.8493 31.4406,64.9872 31.9383,63.9405 L36.3051,54.7569 L39.3993,54.7569 L43.8552,64.1286 C44.3529,65.1744 44.7732,67.0365 44.7732,68.1948 L44.7732,82.2672 C44.7732,84.0537 46.2267,85.5072 48.0132,85.5072 L50.85,85.5072 C52.6365,85.5072 54.09,84.0537 54.09,82.2672 L54.09,68.1948 C54.09,66.7782 53.6481,64.6506 53.0847,63.3501 L48.1698,52.0164 L48.1698,39.0249 L53.9613,50.5467 C54.4968,51.6114 55.7361,52.3548 56.9754,52.3548 C57.1554,52.3548 57.3336,52.3386 57.5082,52.3071 L60.183,51.8103 C60.93,51.6717 61.5321,51.237 61.8336,50.6187 C62.136,50.0004 62.1099,49.2588 61.7607,48.5838 Z" id="Path"></path>
            <path d="M37.8963,19.5048 C43.2738,19.5048 47.6478,15.1299 47.6478,9.7524 C47.6478,4.3749 43.2729,0 37.8963,0 C32.5188,0 28.143,4.3749 28.143,9.7524 C28.143,15.1299 32.5188,19.5048 37.8963,19.5048 Z" id="Path"></path>
        </g>
    </g>
</g>
</svg>
)

export default SVG;