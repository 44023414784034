import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 76 50"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
	<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	<g id="Artboard" transform="translate(-1544.000000, -391.000000)" fill={fill} fillRule="nonzero">
		<g id="games" transform="translate(1544.000000, 391.000000)">
			<path d="M68.3634,8.8676 C58.4472,-7.4242 43.5092,3.9102 43.5092,3.9102 C42.5404,4.6424 40.754,5.2444 39.5402,5.2472 L35.539,5.25 C34.3252,5.2514 32.5388,4.6494 31.5714,3.9172 C31.5714,3.9172 16.632,-7.42 6.7144,8.8718 C-3.2004,25.158 0.7952,42.8946 0.7952,42.8946 C1.4952,47.2374 3.8024,50.1354 8.1564,49.7714 C12.4978,49.4088 21.9212,38.0758 21.9212,38.0758 C22.6996,37.142 24.3306,36.379 25.5416,36.379 L49.5292,36.3748 C50.7416,36.3748 52.3712,37.1378 53.1482,38.0716 C53.1482,38.0716 62.573,49.4046 66.9214,49.7672 C71.2698,50.1312 73.5826,47.2304 74.2784,42.8904 C74.277,42.8904 78.2796,25.1566 68.3634,8.8676 Z M28.5236,22.1284 L23.38,22.1284 L23.38,27.0858 C23.38,27.0858 22.2908,27.9174 20.6052,27.8964 C18.9224,27.8712 18.1608,26.9892 18.1608,26.9892 L18.1608,22.1298 L13.3028,22.1298 C13.3028,22.1298 12.6966,21.5082 12.5342,19.8716 C12.3746,18.235 13.2048,16.912 13.2048,16.912 L18.3498,16.912 L18.3498,11.7684 C18.3498,11.7684 19.4082,11.2014 20.93,11.2448 C22.4532,11.2924 23.569,11.865 23.569,11.865 L23.548,16.9106 L28.406,16.9106 C28.406,16.9106 29.2544,17.9998 29.3244,19.3158 C29.3944,20.6332 28.5236,22.1284 28.5236,22.1284 Z M52.1164,27.7788 C49.8638,27.7788 48.048,25.9574 48.048,23.7076 C48.048,21.4536 49.8638,19.6364 52.1164,19.6364 C54.3592,19.6364 56.189,21.4536 56.189,23.7076 C56.189,25.9588 54.3592,27.7788 52.1164,27.7788 Z M52.1164,16.5774 C49.8638,16.5774 48.048,14.7574 48.048,12.5076 C48.048,10.2536 49.8638,8.4364 52.1164,8.4364 C54.3592,8.4364 56.189,10.2536 56.189,12.5076 C56.189,14.7588 54.3592,16.5774 52.1164,16.5774 Z M62.2552,22.5904 C60.0026,22.5904 58.1868,20.7704 58.1868,18.5192 C58.1868,16.2666 60.0026,14.448 62.2552,14.448 C64.498,14.448 66.3278,16.2666 66.3278,18.5192 C66.3278,20.7704 64.498,22.5904 62.2552,22.5904 Z" id="Shape"></path>
		</g>
	</g>
	</g>
    </svg>
)

export default SVG;