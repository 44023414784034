import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "168px",
    height = "51px",
    className = "",
    viewBox = "0 0 168 51"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <title>Assembly Solutions Ltd</title>
    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Desktop-Homepage-UI" transform="translate(-966.000000, -11.000000)" fill="#FFFFFF">
            <g id="Header-Block" transform="translate(952.000000, 0.000000)">
                <g id="Logo" transform="translate(14.400000, 11.200000)">
                    <path d="M44.9192188,49.2887517 L42.2910687,44.3681015 L6.64706746,44.3681015 C6.23034963,44.3780403 5.94311629,44.2803319 5.78536743,44.0749765 C5.62761858,43.8696211 5.62761858,43.5934127 5.78536743,43.2463515 L27.1934682,6.0172002 C27.5225848,5.39140018 27.8687349,5.07850017 28.2319182,5.07850017 C28.5951015,5.07850017 28.9582849,5.39140018 29.3214682,6.0172002 L54.9099691,49.2887517 L91.3677203,49.2887517 C94.6118705,48.6122017 96.8529789,46.8742766 98.0910456,44.0749765 C99.3291123,41.2756764 98.8514206,38.3890513 96.6579705,35.4151012 L71.5612197,10.1174503 C70.7558284,8.43791867 70.5928451,7.20014363 71.0722697,6.40412522 C71.5516942,5.6081068 72.7262943,5.16623179 74.5960698,5.07850017 L120.215421,5.07850017 C120.061038,26.4326894 120.061038,38.2499731 120.215421,40.5303514 C120.446997,43.9509188 121.302259,45.3661265 123.075709,47.3274391 C124.258009,48.6349808 126.62923,49.2887517 130.189372,49.2887517 L166.530573,49.2887517 L166.530573,44.3681015 L129.068322,44.3681015 C127.48463,44.1726848 126.538026,43.7987682 126.228509,43.2463515 C125.918992,42.6939348 125.611663,41.7886014 125.306522,40.5303514 L125.306522,0.263550009 L71.5612197,0.263550009 C68.6716792,0.936776009 66.8019208,2.5417594 65.9519445,5.07850017 C65.1019681,7.61524095 65.4970598,10.2221577 67.1372195,12.8992504 L92.2721204,38.2455513 C93.5209204,39.4240013 93.8128788,40.7141597 93.1479954,42.1160264 C92.4831121,43.5178931 91.4180037,44.2685848 89.9526703,44.3681015 L57.9084192,44.3681015 L33.6065184,3.37260011 C32.062785,1.12420004 30.2712516,1.36424209e-13 28.2319182,1.36424209e-13 C26.1925848,1.36424209e-13 24.3708347,1.12420004 22.766668,3.37260011 L1.19301728,41.0196514 C-0.261116105,43.4695348 -0.385949443,45.4974932 0.818517265,47.1035266 C2.02298397,48.70956 3.96583404,49.4379684 6.64706746,49.2887517 L44.9192188,49.2887517 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
    </svg>
)

export default SVG;