import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 65 98"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-2265.000000, -62.000000)" fill={fill} fillRule="nonzero">
            <g id="football-player-attempting-to-kick-ball" transform="translate(2265.000000, 62.000000)">
                <path d="M43.5531,0 C47.4273,0 50.5701,3.141 50.5701,7.017 C50.5701,10.8921 47.4273,14.034 43.5531,14.034 C39.6771,14.034 36.5361,10.8921 36.5361,7.017 C36.5361,3.141 39.6771,0 43.5531,0 Z" id="Path"></path>
                <path d="M7.4526,56.7843 C5.208,55.3473 2.2254,56.0013 0.7893,58.2459 C-0.6477,60.4878 0.0063,63.4734 2.2512,64.9086 L16.1202,73.791 C18.2253,75.1362 21.009,74.655 22.5375,72.6789 L39.5016,50.7477 L52.6779,67.7499 L54.6075,91.1742 C54.8139,93.6939 56.9253,95.6016 59.4093,95.6016 C59.541,95.6016 59.6766,95.5953 59.8101,95.586 C62.4648,95.367 64.4409,93.0384 64.2216,90.3819 L62.1759,65.5332 C62.0997,64.6011 61.7535,63.7128 61.182,62.9733 L50.4336,46.7103 L51.3021,24.6336 C51.3021,24.6336 51.546,23.3628 51.5928,21.7992 C52.9584,20.1993 54.7557,17.9622 57.1557,14.8125 C60.7518,10.0947 64.0608,5.478 64.095,5.4321 C65.1525,3.954 64.8126,1.9005 63.3354,0.8424 C61.8594,-0.2148 59.8041,0.1254 58.7463,1.6014 C55.1487,6.6282 50.9607,12.1896 47.9112,15.9435 C44.037,15.4923 38.5767,15.4806 35.3688,16.0818 C34.2267,16.2969 33.5076,16.7901 33.051,17.358 C32.9415,17.4723 32.835,17.5917 32.7411,17.7255 C27.0291,25.7049 19.8288,35.0355 18.0588,36.3363 C16.5201,37.1706 15.8973,39.0759 16.6716,40.6668 C17.2407,41.8371 18.4122,42.5184 19.6317,42.5184 C20.1156,42.5184 20.6058,42.4122 21.0687,42.186 C21.9627,41.7501 23.4609,41.0217 31.152,30.9366 C31.4286,30.5733 31.7028,30.2127 31.9746,29.8533 L31.5705,42.0297 L31.6452,42.0492 L17.6082,63.2886 L7.4526,56.7843 Z" id="Path"></path>
                <path d="M39.2763,82.0044 C43.4553,82.0044 46.8417,85.3926 46.8417,89.5713 C46.8417,93.7485 43.4553,97.1367 39.2763,97.1367 C35.0991,97.1367 31.7112,93.7485 31.7112,89.5713 C31.7112,85.3926 35.0991,82.0044 39.2763,82.0044 Z" id="Path"></path>
            </g>
        </g>
    </g>
    </svg>
)

export default SVG;