import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "27px",
    height = "29px",
    className = "",
    viewBox = "0 0 27 29"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Header-Block" transform="translate(-480.000000, -22.000000)" fill="#FFFFFF" fill-rule="nonzero">
        <g id="Group-4" transform="translate(425.600006, 0.000000)">
            <g id="chat" transform="translate(54.400001, 22.400000)">
                <path d="M13.2612016,0 C13.2539983,0 13.2395918,0 13.217982,0 C5.92828294,0 0,5.99776425 0,13.3729008 C0,16.3827145 1.01565965,19.3050759 2.87410072,21.658998 L0.950830313,26.18465 C0.792358594,26.5563219 0.965236833,26.9862953 1.32539983,27.1393367 C1.45505851,27.1976381 1.59912371,27.2122135 1.73598565,27.1903504 L8.78797713,25.9368687 C10.1998161,26.4542943 11.6836876,26.716651 13.1819657,26.7093633 C20.4716648,26.7093633 26.3999741,20.711599 26.3999741,13.3364624 C26.4143542,5.98318888 20.5292908,0.00728768439 13.2612016,0 Z M8.73755431,10.6837453 L12.6273147,10.6837453 C13.023494,10.6837453 13.3476407,11.0116911 13.3476407,11.4125137 C13.3476407,11.8133364 13.023494,12.1412822 12.6273147,12.1412822 L8.73755431,12.1412822 C8.34137502,12.1412822 8.01722832,11.8133364 8.01722832,11.4125137 C8.01722832,11.0116911 8.34137502,10.6837453 8.73755431,10.6837453 Z M17.6695966,15.7851244 L8.73755431,15.7851244 C8.34137502,15.7851244 8.01722832,15.4571786 8.01722832,15.0563559 C8.01722832,14.6555333 8.34137502,14.3275875 8.73755431,14.3275875 L17.6695966,14.3275875 C18.0657759,14.3275875 18.3899226,14.6555333 18.3899226,15.0563559 C18.3899226,15.4571786 18.0657759,15.7851244 17.6695966,15.7851244 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</g>
</svg>
)

export default SVG;