import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 76 99"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
	<g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
	<g id="Artboard" transform="translate(-1391.000000, -57.000000)" fill={fill} fillRule="nonzero">
		<g id="archery-skill" transform="translate(1391.000000, 57.000000)">
			<path d="M31.4054,63.1152 L31.4054,59.4666 L12.3344,59.4666 L12.3344,67.4748 C12.3344,67.8654 12.3596,68.1678 12.4046,68.4108 C12.0572,76.3992 12.0338,86.2002 12.0338,93.9798 C12.0338,96.6942 14.2388,98.8938 16.9532,98.8938 C19.6676,98.8938 21.869,96.6888 21.869,93.9798 C21.869,86.3676 21.8888,72.7236 22.2182,65.0034 C24.002,74.5686 26.648,88.8318 30.1076,95.9688 C30.9572,97.7238 32.7104,98.7462 34.5374,98.7462 C35.2556,98.7462 35.9864,98.5842 36.6794,98.2548 C39.122,97.0704 40.1408,94.131 38.9564,91.6848 C34.8524,83.2104 32.7356,71.2332 31.4054,63.1152 Z" id="Path"></path>
			<path d="M19.7486,19.8342 C20.0816,21.2796 19.8296,22.7664 19.0412,24.0228 C18.2708,25.2486 17.0774,26.1054 15.6752,26.451 C16.8884,27.2484 18.3374,27.7164 19.898,27.7164 C21.4784,27.7164 22.9436,27.2394 24.1658,26.424 L20.555,26.424 L22.4612,23.3046 L20.645,20.0124 L27.6002,20.0124 C27.6002,15.759 24.1514,12.3102 19.898,12.3102 C17.297,12.3102 15.002,13.6044 13.6088,15.5754 C13.8518,15.5448 14.0966,15.5232 14.3378,15.5232 C16.9406,15.525 19.1636,17.2962 19.7486,19.8342 Z" id="Path"></path>
			<path d="M75.167,23.0922 L70.5158,18.9702 L70.5158,22.1202 L63.6956,22.1202 C60.641,3.5586 49.6268,0.1548 49.085,0 L48.5936,1.719 C48.716,1.7532 58.8806,4.8978 61.8722,22.1202 L28.6982,22.1202 L28.0286,20.8944 L22.859,20.8944 L24.1316,23.2038 L22.859,25.2882 L28.0268,25.2882 L28.5794,24.0624 L62.171,24.0624 C62.3888,25.6878 62.5436,27.4302 62.6246,29.2914 C61.9856,28.9332 61.2494,28.7118 60.4664,28.7118 L21.8744,28.7118 C21.5,28.7118 21.1418,28.7712 20.7926,28.8576 C17.5022,29.2302 14.723,31.2858 13.319,34.1406 C8.1836,29.7576 8.2016,27.9306 8.198,27.9306 C8.2034,27.918 8.9324,26.5086 14.4404,25.2468 C16.6148,24.7482 17.972,22.5828 17.4734,20.4084 C16.9766,18.234 14.8076,16.8822 12.635,17.3754 C9.9584,17.9892 1.8116,19.8594 0.3446,26.0568 C-1.0486,31.9464 4.508,37.3482 8.81,40.8996 C9.5606,41.5206 10.4714,41.823 11.3786,41.823 C11.7026,41.823 12.0212,41.7744 12.338,41.6988 L12.338,57.3174 L31.409,57.3174 L31.409,38.3292 C31.409,38.1006 31.3892,37.8774 31.373,37.6524 L60.461,37.6524 C61.2134,37.6524 61.91,37.449 62.531,37.1232 C60.9614,59.346 48.6386,64.62 48.509,64.6722 L49.1732,66.3354 C49.7744,66.0924 63.4508,60.3306 64.4282,35.208 C64.745,34.5978 64.934,33.9156 64.934,33.1812 C64.934,32.4774 64.754,31.8222 64.466,31.23 C64.4282,28.6362 64.259,26.2512 63.9782,24.0624 L70.5212,24.0624 L70.5212,27.2124 L75.167,23.0922 Z" id="Path"></path>
		</g>
	</g>
	</g>
    </svg>
)

export default SVG;