import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 100 91"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
    <g id="Artboard" transform="translate(-2224.000000, -213.000000)" fill={fill} fillRule="nonzero">
        <g id="ski" transform="translate(2224.000000, 213.000000)">
            <path d="M93.1412,23.3316 C97.884,21.0042 99.8446,15.2754 97.5174,10.5346 C95.1958,5.7898 89.4652,3.831 84.7222,6.1564 C79.9814,8.48 78.0222,14.2092 80.346,18.95 C82.6698,23.6928 88.3984,25.6534 93.1412,23.3316 Z" id="XMLID_547_"></path>
            <path d="M67.1898,35.659 C66.4266,39.3786 66.7614,42.7088 70.4674,43.7286 C70.4674,43.7286 87.5156,48.4112 87.5228,48.4132 L87.5324,48.4152 C90.3014,49.1732 93.1358,47.5434 93.8896,44.7968 C94.6438,42.0464 93.031,39.2026 90.2806,38.4432 C90.2772,38.4412 90.2752,38.4412 90.2714,38.4392 L81.5772,36.0516 L78.1402,33.758 C79.1806,28.6972 78.8102,30.4934 79.7362,25.9934 C80.2244,23.6232 78.4076,20.5924 77.3432,18.4184 C75.3394,14.3342 70.4076,12.6464 66.3254,14.6484 L60.6156,17.4476 L69.723,23.5298 C69.5136,24.252 69.0196,26.7514 68.8552,27.5562 L28.6336,0.703 C26.8788,-0.476 24.497,-0.0006 23.3204,1.7616 C22.1452,3.5202 22.6188,5.9002 24.379,7.075 L67.1898,35.659 Z" id="XMLID_548_"></path>
            <path d="M98.7654,80.582 C97.6822,78.7674 95.334,78.178 93.5098,79.2632 L88.234,82.4194 L53.3338,70.4082 L69.7904,56.5444 C72.9184,53.9122 72.7576,49.0194 69.4406,46.6006 L60.232,39.8894 L63.538,38.2694 L63.9196,38.082 L44.6996,25.2494 L44.6958,25.2512 C41.487,26.8246 39.4458,29.8072 38.9106,33.0846 C38.2466,37.1298 39.9378,40.649 43.7618,43.532 L55.476,52.069 L39.5264,65.6558 L5.0804,53.7998 C3.0748,53.1056 0.897,54.1718 0.2102,56.174 C-0.4802,58.176 0.5844,60.3556 2.5844,61.0458 L87.4234,90.2454 C87.8296,90.3858 88.2526,90.455 88.6716,90.455 C89.3564,90.455 90.0374,90.2716 90.64,89.9104 L97.4464,85.8374 C99.2612,84.7522 99.8524,82.3986 98.7654,80.582 Z" id="XMLID_549_"></path>
        </g>
    </g>
    </g>
    </svg>
)

export default SVG;