import React from 'react'

const SVG = ({
    style = {},
    fill = "#00ABAC",
    textFill = "",
    stroke = "none",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 50 50"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g id="Desktop" stroke={stroke} strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-150.000000, -1422.000000)" fill={fill} fillRule="nonzero">
            <g id="linkedin" transform="translate(150.000000, 1422.000000)">
                <path d="M25,0 C11.1949921,0 0,11.1949921 0,25 C0,38.8050079 11.1949921,50 25,50 C38.8050079,50 50,38.8050079 50,25 C50,11.1949921 38.8050079,0 25,0 Z M17.7352905,37.7929688 L11.6466522,37.7929688 L11.6466522,19.4751739 L17.7352905,19.4751739 L17.7352905,37.7929688 Z M14.6911621,16.973877 L14.6514893,16.973877 C12.6083374,16.973877 11.2869263,15.567398 11.2869263,13.8095855 C11.2869263,12.0121002 12.6487732,10.6445312 14.7315979,10.6445312 C16.8144227,10.6445312 18.0961609,12.0121002 18.1358338,13.8095855 C18.1358338,15.567398 16.8144227,16.973877 14.6911621,16.973877 Z M39.6896362,37.7929688 L33.6017608,37.7929688 L33.6017608,27.993393 C33.6017608,25.5306244 32.7201844,23.8510132 30.5171967,23.8510132 C28.8352967,23.8510132 27.8335571,24.9839783 27.393341,26.077652 C27.2323608,26.4690399 27.1930694,27.0160675 27.1930694,27.5634766 L27.1930694,37.7929688 L21.1048126,37.7929688 C21.1048126,37.7929688 21.1845398,21.1936951 21.1048126,19.4751739 L27.1930694,19.4751739 L27.1930694,22.0687866 C28.0021668,20.8206177 29.4498443,19.0452576 32.68013,19.0452576 C36.6859436,19.0452576 39.6896362,21.6632843 39.6896362,27.2895813 L39.6896362,37.7929688 Z" id="Shape"></path>
            </g>
        </g>
    </g>
    </svg>
)

export default SVG;