import React from 'react'

const SVG = ({
    style = {},
    fill = "",
    stroke = "",
    width = "50px",
    height = "50px",
    className = "",
    viewBox = "0 0 60 103"
}) => (
    <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-1552.000000, -213.000000)" fill={fill} fillRule="nonzero">
            <g id="golfer" transform="translate(1552.000000, 213.000000)">
                <path d="M12.228125,15.3367188 L15.7445312,13.7414062 L14.2257812,11.3898438 C13.1273438,9.6898438 10.859375,9.2023438 9.159375,10.3 C8.1804688,10.9328124 7.60625,11.953125 7.503125,13.0296876 C8.4851562,13.1429688 9.45625,13.4828124 10.3351562,14.0703124 L12.228125,15.3367188 Z" id="Path"></path>
                <path d="M1.4148438,22.9835938 L1.271875,23.0476562 C0.2679688,23.5039062 -0.1765624,24.6867188 0.2789062,25.690625 C0.7328124,26.6921876 1.9148438,27.140625 2.9210938,26.6835938 L4.796875,25.8328124 L3.1882812,24.7570312 C2.4640624,24.2726562 1.8703124,23.665625 1.4148438,22.9835938 Z" id="Path"></path>
                <path d="M47.2640624,6.565625 L50.5601562,5.0695312 C50.7492188,5.6476562 51.015625,6.2375 51.346875,6.7882812 C52.9054688,9.3773438 55.4890624,10.68125 57.1179688,9.7007812 C58.7460938,8.7210938 58.803125,5.8273438 57.2453124,3.2382812 C56.0101562,1.1867188 54.1328124,-0.0570312 52.5953124,0.00234376 C51.5382812,0.0734376 47.4734376,2.078125 42.671875,4.2648438 C44.3945312,4.6554688 45.9617188,5.4585938 47.2640624,6.565625 L47.2640624,6.565625 Z" id="Path"></path>
                <path d="M29.4851562,14.6320312 C29.5117188,12.8351562 29.9765624,11.1429688 30.7820312,9.659375 L14.6695312,16.9695312 L18.5757812,19.58125 L29.4851562,14.6320312 Z" id="Path"></path>
                <path d="M59.1390624,95.78125 L42.46875,56.3078124 L47.0140624,33.3289062 C47.590625,30.4140624 45.6953124,27.584375 42.78125,27.0078124 L32.9421876,25.0617188 C31.7390624,24.8234376 30.55,24.9898438 29.5140624,25.4648438 L38.415625,27.74375 L24.35,26.7679688 L8.7984376,16.3679688 C7.1164062,15.2429688 4.840625,15.6945312 3.715625,17.3765624 C2.590625,19.059375 3.0421876,21.334375 4.725,22.459375 L21.0867188,33.4015624 C21.6179688,33.75625 22.2328124,33.9664062 22.8695312,34.0109376 L37.9078124,35.0546876 L24.946875,36.5125 L20.8148438,57.4023438 C20.8148438,57.4023438 20.8351562,69.5734376 20.8164062,97.634375 C20.8164062,100.265625 22.9492188,102.399219 25.58125,102.399219 C28.2125,102.399219 30.3453124,100.265625 30.3453124,97.634375 L30.3453124,59.2875 L33.6601562,59.9429688 L50.3609376,99.4882812 C51.3882812,101.920312 54.1875,103.044531 56.6039062,102.023438 C59.0273438,101 60.1632812,98.2046876 59.1390624,95.78125 L59.1390624,95.78125 Z" id="Path"></path>
                <path d="M48.509375,14.79375 C48.509375,19.3382812 44.825,23.0226562 40.2804688,23.0226562 C35.7359376,23.0226562 32.0515624,19.3382812 32.0515624,14.79375 C32.0515624,10.2492188 35.7359376,6.565625 40.2804688,6.565625 C44.825,6.565625 48.509375,10.2492188 48.509375,14.79375 Z" id="Path"></path>
            </g>
        </g>
    </g>
    </svg>
)

export default SVG;